import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './InstagramFields.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true,
    autoplaySpeed: 1500, // Autoplay speed in milliseconds
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 2,
              slidesPerRow: 2
            },
        },
    ],
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://lf8zok4ab8.execute-api.eu-west-1.amazonaws.com/Test/instagram-post');
    
        setPosts(response.data.body);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };
    console.log(posts);
    fetchPosts();
  }, []);

  const display_img = (data, alt) => {
    const img = "data:image/webp;base64," + data

    return <img crossOrigin="anonymous" src={img} alt={alt} className='postImg'/>;
  }

  return (
    <>
    {posts !== undefined ? (
        <div className='container'>
            <Slider {...settings} className='slider'>
                {posts.map(post => (
                <a key={post.node.id} target='blank' href={`https://www.instagram.com/p/${post.node.shortcode}?img_index=1`} className='card'>
                  <div className='card-container'>
                    {display_img(post.node.display_url, post.node.accessibility_caption)}
                    <div className='card-body'>
                      <p>
                        {post.node.edge_media_to_caption.edges[0]?.node.text}
                      </p>
                    </div>
                  </div>
                </a>
                ))}
            </Slider>
        </div>
    ): (<></>)}
    </>
  );
};

export default InstagramFeed;

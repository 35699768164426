import './App.css';
import Header from './components/Header';
import { useParams } from 'react-router-dom';
import Product from './components/Product';
import React, { useState, useEffect } from 'react';

function ProductPage({products}) {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [items, setItems] = useState(null);

  useEffect(() => {
    console.log(id);
    setProduct(products.find(p => p.id === id));
    setItems(products.filter(p => p.id !== id));
    console.log(products);
  }, [id, products]);
  
  return (
    <div className="App">
      <Header/>
      {items && <Product products={items} product={product}/> }
    </div>
  );
}

export default ProductPage;

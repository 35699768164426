// itemReducer.js
import { ADD_ITEM, REMOVE_ITEM, UPDATE_ITEM, RESET_ITEMS } from './action';

const initialState = {
  items: []
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      const found = state.items.some(el => el.id === action.item.id && el.size === action.item.size);

      if (!found) {
        return {
          ...state,
          items: [...state.items, action.item]
        }
      } else {
        const objIndex = state.items.findIndex((obj => obj.id === action.item.id && obj.size === action.item.size));
        state.items[objIndex].quantity += action.item.quantity;
      }
      return {
        ...state,
        items: [...state.items]
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter((_, index) => index !== action.index)
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item, index) =>
          index === action.index ? action.item : item
        )
      };
    case RESET_ITEMS:
      return {
        ...state,
        items: [],
      }
    default:
      return state;
  }
};

export default itemReducer;

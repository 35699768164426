import React, { useState, useEffect } from 'react';
import './Product.css';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { addItem } from '../action';
import Footer from './Footer';

const Item = ({tittle, price, img}) =>  {
    return (
        <div className='card__container__product'>
            <img src={"https://d2dwc30lmcj2tv.cloudfront.net/" + img[0]} alt={tittle} className='item__img'/>
        </div>
    )
}

const Product = ({products, product}) => {
    const { items } = useSelector(state => state.items);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const sizes = ["S", "M", "L", "XL"];
    const sizesSecond = ["A3", "A2"];
    const [selectedSize, setSelectedSize] = useState("S");

    useEffect(() => {
        if (product.price === "false") {
            setSelectedSize("A3");
        }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll
        autoplay: true,
        autoplaySpeed: 1500, // Autoplay speed in milliseconds
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
      };

    const settings2 = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
      };

    const handleSizeClick = (size) => {
        setSelectedSize(size);
    };

    const handleAdd = () => {
        let newProductOrder
        if (product.price !== "false") {
            newProductOrder = {
                id: product.id,
                name: product.name,
                quantity: 1,
                size: selectedSize,
                price: product.price,
                description: product.description,
                image: product.images[0]
            }
        } else {
            newProductOrder = {
                id: product.id,
                name: product.name,
                quantity: 1,
                size: selectedSize,
                price: product.infos[selectedSize],
                description: product.description,
                image: product.images[0]
            }           
        }

        dispatch(addItem(newProductOrder));
        setOpenModal(true);
    };

    return (
        <div className='product__container'>
            <section className='product'>
                <div className='product__img'>
                    <Slider {...settings2} className='slider'>
                        {product.images.map((image, index) => (
                            <img alt='product' src={"https://d2dwc30lmcj2tv.cloudfront.net/" + image} key={index}></img>
                        ))}
                    </Slider>
                </div>
                <div className='product__description'>
                    <h1>{product.name}</h1>
                    <p>€{(product.price !== "false") ? product.price : product.infos[selectedSize]}</p>
                    <p dangerouslySetInnerHTML={{__html: product.description}}></p>
                    <div className="sizeContainer">
                        {!(product.price !== "false" && product.size === true ) ? <></> : sizes.map((size, index) => (
                            <button 
                                role="checkbox" 
                                aria-checked={selectedSize === size}
                                className={"ui--dot-item is-dot " + (selectedSize === size ? 'is-selected' : '')}
                                onClick={() => handleSizeClick(size)}>
                                <span className='text'>
                                    <span className='text__label'>{size}</span>
                                </span>
                            </button>
                        ))}
                        {!(product.price === "false" && product.size === true ) ? <></> : sizesSecond.map((size, index) => (
                            <button 
                                role="checkbox" 
                                aria-checked={selectedSize === size}
                                className={"ui--dot-item is-dot " + (selectedSize === size ? 'is-selected' : '')}
                                onClick={() => handleSizeClick(size)}>
                                <span className='text'>
                                    <span className='text__label'>{size}</span>
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className='add__cart'>
                        <input type="button" className="button" value="Ajouter au panier" onClick={() => handleAdd()}/>
                    </div>
                </div>
            </section>
            <section className='product__selection'>
                <Slider {...settings} className='product__slider'>
                    {products.map(item => (
                        <Link to={"/product/" + item.id} className='card__product' key={item.id}>
                            <Item tittle={item.name} price={item.price} img={item.images}/>
                        </Link>
                    ))}
                </Slider>
                <img src="./Anomalie.svg" alt='anomalie' className='seven_ano'/>
                <img src="./Anomalie.svg" alt='anomalie' className='eight__ano'/>
            </section>
            {openModal && 
            <section className='confirm'>
                <div onClick={() => setOpenModal(false)} className='confirm__close__zone'></div>
                <div className='confirm__container'>
                    <div className='confirm__close' onClick={() => setOpenModal(false)}>X</div>
                    <div>
                        <h1>Produit ajouté</h1>
                        <div className='confirm__infos'>
                            <img alt='product' src={"https://d2dwc30lmcj2tv.cloudfront.net/" + product.images[0]}></img>
                            <div>
                                <p>{product.price} €</p>
                                <p>{product.name}</p>
                                <p>Taille: {selectedSize}</p>
                            </div>
                        </div>
                        <div className='confirm__action'>
                            <Link to={"/cart"}>
                                <input type="button" className="button" value={"Voir mon panier (" + items.reduce((sum, item) => sum + item.quantity, 0) + ")"}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Footer/>
        </div>
    );
};

const mapDispatchToProps = {
    addItem
};
  
export default connect(
    null,
    mapDispatchToProps
)(Product);
// actions.js
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const RESET_ITEMS = 'RESET_ITEMS';

export const addItem = (item) => ({
  type: ADD_ITEM,
  item
});

export const removeItem = (index) => ({
  type: REMOVE_ITEM,
  index
});

export const updateItem = (index, item) => ({
  type: UPDATE_ITEM,
  index,
  item
});

export const updateItemQuantity = (index, item) => ({
    type: UPDATE_ITEM_QUANTITY,
    index,
    item
});

export const resetItems = () => ({
  type: RESET_ITEMS
});

import './App.css';
import HomePage from './HomePage.js';
import ProductPage from './ProductPage';
import React, { useState, useEffect } from 'react';
import Api from './api/Api';
import { Route, Routes } from "react-router-dom";
import CartPage from './CartPage';
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from'./store';
import { Provider } from 'react-redux';
import OrderSummaryPage from './OrderSummaryPage';
import ScrollToTop from './ScrollToTop';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  const [products, setProducts] = useState(null);
  
  const loadProducts = () => {
    let header = {
    }

    Api.get('/products', header)
        .then(function (response) {
            console.log("products load success");
            setProducts(response.data.body.sort((a, b) => {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            }));
        })
        .catch(function (error) {
            console.log("products load error response :: ", error);
        });
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <>
      <PayPalScriptProvider options={{ "client-id": "AV9qUXMWgsDtSqB1ucUg8_ZXKiNMgsNQYxDfJongXrYlGz8syHooF9c0v-gTkFEDfQQrkOqM83V7qJgB", currency: "EUR" }}>
        <Provider store={store}>
          <PersistGate loading={<p>Loading...</p>} persistor={persistor}></PersistGate>
          <Routes>
            <Route exact path="/" element={products && <HomePage products={products}/>}></Route>
            <Route path="/product/:id" element={products && <ProductPage products={products}/> }></Route>
            <Route path="/cart" element={<CartPage/> }></Route>
            <Route path="/cart/infos" element={<CartPage/> }></Route>
            <Route path="/order/summary" element={<OrderSummaryPage/> }></Route>
          </Routes>
        </Provider>
        <ScrollToTop />
      </PayPalScriptProvider>
    </>
  );
}

export default App;

import React, { useState } from 'react';
import './Alert.css'

const Info = ({title, message, onClose}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            onClose();
        }
    }

    return (
        <label>
            <input type="checkbox" className="alertCheckbox" 
            autoComplete="off" onChange={handleCheckboxChange}  />
            <div className="alert style.info">
                <span className="alertClose">X</span>
                <span className="alertText">{message}
                <br className="clear"/></span>
            </div>
        </label>
    );
}

const Success = ({title, message, onClose}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            onClose();
        }
    }

    return (
        <label>
            <input type="checkbox" className="alertCheckbox"
            autoComplete="off" onChange={handleCheckboxChange}  />
            <div className="alert success">
                <span className="alertClose">X</span>
                <span className="alertText">{message}
                <br className="clear"/></span>
            </div>
        </label>
    );
}

const Notice = ({title, message, onClose}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            onClose();
        }
    }

    return (
        <label>
            <input type="checkbox" className="alertCheckbox"
            autoComplete="off" onChange={handleCheckboxChange}  />
            <div className="alert notice">
                <span className="alertClose">X</span>
                <span className="alertText">{message}
                <br className="clear"/></span>
            </div>
        </label>
    );
}

const Warning = ({title, message, onClose}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            onClose();
        }
    }

    return (
        <label>
            <input type="checkbox" className="alertCheckbox"
            autoComplete="off" onChange={handleCheckboxChange}  />
            <div className="alert warning">
                <span className="alertClose">X</span>
                <span className="alertText">{message}
                <br className="clear"/></span>
            </div>
        </label>
    );
}

const Error = ({title, message, onClose}) => {
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            onClose();
        }
    }

    return (
        <label>
            <input type="checkbox" className="alertCheckbox"
            autoComplete="off" onChange={handleCheckboxChange}  />
            <div className="alert error">
                <span className="alertClose">X</span>
                <span className="alertText">{message}
                <br className="clear"/></span>
            </div>
        </label>
    );
}

const CustomAlert = ({ alerts, setAlerts }) => {

    const closeAlert = async (id) => {
        const updatedItems = [...alerts];
        updatedItems.splice(id, 1);
        setAlerts(updatedItems);
    }

    const renderComponent = (title, type, message, index) => {
        switch (type) {
            case 'info':
                return <Info title={title} message={message} onClose={() => closeAlert(index)}/>;
            case 'success':
                return <Success title={title} message={message} onClose={() => closeAlert(index)}/>;
            case 'notice':
                return <Notice title={title} message={message} onClose={() => closeAlert(index)}/>;
            case 'warning':
                return <Warning title={title} message={message} onClose={() => closeAlert(index)}/>;
            case 'error':
                return <Error title={title} message={message} onClose={() => closeAlert(index)}/>
            default:
                return (
                <Info title={title} message={message} onClose={() => closeAlert(index)}/>
                );
        }
    };

    return (
        <div className="custom__alert">
            {alerts !== undefined && alerts.map((key, index) => (
                <div key={index}>
                    {renderComponent(key.title, key.type, key.message, index)}
                </div>
            ))}
        </div>
    );
};

export default CustomAlert;
import './App.css';
import Header from './components/Header';
import OrderSummary from './components/OrderSummary';

function OrderSummaryPage() {
  return (
    <div className="App">
      <Header/>
      <OrderSummary />
    </div>
  );
}

export default OrderSummaryPage;

import './App.css';
import Cart from './components/Cart';
import Header from './components/Header';

function CartPage() {
  return (
    <div className="App">
      <Header/>
      <Cart />
    </div>
  );
}

export default CartPage;

import React from 'react';
import './Shop.css'
import { Link } from "react-router-dom";

const Item = ({tittle, price, img}) =>  {
    return (
        <div>
            <div className='item__img__shop' 
            style={{backgroundImage: "url('https://d2dwc30lmcj2tv.cloudfront.net/" + img[0] + "')"}}>
            </div>
            <div className='item__infos'>
                <h2>{tittle}</h2>
                <p>€{price}</p>
            </div>
        </div>
    )
}

const Shop = ({products}) => {
    return (
        <>
            <section className='shop'>
                <div className='shop__container'>
                    <div className='tittle'>
                        <img src='./shop pc.png' alt='shop' className='shop__tittle'/>
                        <img src='./shop tel.png' alt='shoptel' className='shop__tel__tittle'/>
                    </div>
                    <div className='container'>
                        {products.map((item, index) => (
                            <Link to={"/product/" + item.id} key={index}><Item tittle={item.name} price={(item.price !== "false") ? item.price : Object.values(item.infos)[0]} img={item.images}/></Link>
                        ))}
                    </div>
                    <div className='tittle tittle__mobile'>
                        <img src='./shop pc.png' alt='shop2' className='shop__tittle'/>
                    </div>
                </div>
                <div className='place'>
                    <h1>CHOPE TA PLACE !!!</h1>
                    <div>
                        <a href='https://www.helloasso.com/associations/morphoz/evenements/megasouye-8' rel="noreferrer" target='_blank'>https://www.helloasso.com/associations/morphoz</a>
                    </div>
                </div>
                <img src="./ANOMALIE ORANGE.svg" alt='anomalie' className='nine__ano'/>
                <img src="./ANOMALIE ORANGE.svg" alt='anomalie' className='ten__ano'/>
                <img src="./ANOMALIE ORANGE.svg" alt='anomalie' className='eleven__ano'/>
                <img src="./ANOMALIE VERT.svg" alt='anomalie' className='twelve__ano'/>
                <img src="./ANOMALIE VERT.svg" alt='anomalie' className='tbis_ano'/>
                <img src="./ANOMALIE VERT.svg" alt='anomalie' className='bthred__ano'/>
            </section>
        </>
    );
};

export default Shop;
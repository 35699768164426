import React, { useState, useEffect } from 'react';
import './Cart.css';
import { useSelector, useDispatch } from 'react-redux';
import { removeItem, updateItem } from '../action';
import { Link } from "react-router-dom";
import Footer from './Footer';

const Item = ({item, remove, update}) =>  {
    const removeArticle = () => {
        if (item.quantity - 1 > 0) {
            update(item.quantity - 1);
        } else if (item.quantity - 1 === 0) {
            remove();
        }
    };
  
    const addArticle = () => {
        update(item.quantity + 1);
    };

    const deleteArticle = () => {
        remove();
    }

    return (
        <div className='cart__item'>
            <Link to={"/product/" + item.id}><img src={"https://d2dwc30lmcj2tv.cloudfront.net/" + item.image} alt={item.name} className='item__cart__img'/></Link>
            <div>
                <div className='cart__description'>
                    <div>
                        <p>{item.name}</p>
                        <p>{item.price} EUR</p>
                        <p>{item.size} | size</p>
                    </div>
                    <div className='cart__remove' onClick={deleteArticle}>X</div>
                </div>
                <div className='cart__action'>
                    <div onClick={removeArticle}>-</div>
                    <div>{item.quantity}</div>
                    <div onClick={addArticle}>+</div>
                </div>
            </div>
        </div>
    )
}

const Cart = () => {
    const dispatch = useDispatch();
    const [totalAmount, setTotalAmount] = useState(0);
    const { items } = useSelector(state => state.items);
    
    const updateTotalAmount = () => {
        const newTotalAmount = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
        setTotalAmount(newTotalAmount);
    };

    useEffect(() => {
        updateTotalAmount();
    }, [items, totalAmount]);

    const handleRemove = (index) => {
        dispatch(removeItem(index));
    };

    const handleUpdate = (index, item, newQuantity) => {
        item.quantity = newQuantity;
        dispatch(updateItem(index, item));
    };

    return (
        <div className='product__container'>
            <div>
                {items.length > 0 ? (
                    <section className='cart'>
                        {items.map((item, index) => (
                            <Item item={item} key={index} remove={() => handleRemove(index)} update={(newQuantity) => handleUpdate(index, item, newQuantity)}></Item>
                        ))}
                    </section>
                ) : (
                    <section className="empty__cart__message">
                        Your cart is empty.
                    </section>
                )}
                <section className='cart__footer'>
                    <div></div>
                    <div>
                        Total:  {totalAmount} EUR
                    </div>
                    <Link to={items.length > 0 ? '/order/summary' : '#'} className='go__to__order'><div>Continuer</div></Link>
                </section>
            </div>
            <Footer/>
        </div>
    );
};

export default Cart;
import React from 'react';
import './Infos.css'

const Infos = () => {

    return (
        <>
            <section className='infos'>
                <div className='tittle'>
                    <img src='./info.png' alt='infos'/>
                </div>
                <div className='container'>
                    <h2>Qui sommes-nous ?</h2>
                    <p>Un groupe d’amis animé par l’envie de se réunir afin d’organiser des événements de partage. À travers une association qui permet la création de futurs événements et l’amélioration de son noyau principal, un événement annuel : la Megasouye.</p>
                    <h2>Notre objectif ?</h2>
                    <p>L’organisation de divers événements festifs, dans le but de tous nous réunir.</p>
                </div>
                <img src="./Anomalie.svg" alt='anomalie' className='second__ano'/>
                <img src="./Anomalie.svg" alt='anomalie' className='third__ano'/>
                <img src="./Anomalie.svg" alt='anomalie' className='four__ano'/>
                <img src="./Anomalie.svg" alt='anomalie' className='fifth__ano'/>
                <img src="./Anomalie.svg" alt='anomalie' className='six__ano'/>
            </section>
        </>
    );
};

export default Infos;
import React from 'react';
import './Header.css'
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <header>
                <div className='header__blank'></div>
                <Link to="/" className='header__logo'><img src='./logo.svg' alt='logo'/></Link>
                <Link to="/cart"><img src='./panier.svg' alt='panier'/></Link>
            </header>
        </>
    );
};

export default Header;
import './App.css';
import Actu from './components/Actu';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Infos from './components/Infos';
import Shop from './components/Shop';

function HomePage({products}) {
  return (
    <div className="App">
      <Header/>
      <div className='home__container'>
        <Home/>
        <Shop products={products}/>
        <Infos/>
        <Footer/>
      </div>
    </div>
  );
}

export default HomePage;

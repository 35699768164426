import React, { useState, useEffect, useRef, forwardRef } from 'react';
import './Cart.css';
import { useSelector, useDispatch } from 'react-redux';
import Footer from './Footer';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { resetItems } from '../action';
import Api from '../api/Api';
import CustomAlert from './Alert';

const PayPalButtonsWrapper = forwardRef((props, ref) => (
    <div ref={ref}>
      <PayPalButtons {...props} />
    </div>
));

const Item = ({item}) =>  {
    return (
        <div className='order__item'>
            <img src={"https://d2dwc30lmcj2tv.cloudfront.net/" + item.image} alt={item.name} className='item__order__img'/>
            <div>{item.quantity}</div>
        </div>
    )
}

const OrderSummary = () => {
    const [totalAmount, setTotalAmount] = useState(0);
    const dispatch = useDispatch();
    const [{ isPending }] = usePayPalScriptReducer();
    const itemsState = useSelector(state => state.items);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [items, setItems] = useState([]);
    const [checkout, setCheckout] = useState(0);
    const paypalButtonRef = useRef(null);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [alerts, setAlerts] = useState([]);
    
    const addAlert = (title, message, type) => {
        const newAlert = { title, message, type };
        setAlerts(prevAlerts => [...prevAlerts, newAlert]);
        console.log(alerts);
    };

    const handleSubmit = (event) => {
    };

    const updateTotalAmount = () => {
        const newTotalAmount = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
        setTotalAmount(newTotalAmount);
    };

    useEffect(() => {
        setItems(itemsState.items);
        setCheckout(checkout + 1);
        if (Array.isArray(items)) {
            updateTotalAmount();
        }
    }, [items, totalAmount, itemsState]);
    
    const handlePayPalClick = (data, actions) => {
        if (!firstName || !lastName || !email || !isCheckboxChecked) {
            console.log("reject");
            return actions.reject();
            
        } else {
            console.log("aprove");
            return actions.resolve();
        }
      };

    const onCreateOrder = (data,actions) => {
        addAlert("", "Commande indisponible", "success");
       /*
        const order = {
            email: email,
            firstname: firstName,
            lastname: lastName,
            items: items
        };
        let header = {
            'Content-Type': 'application/json'
        };
        console.log(order);
        return Api.put('/orders', order, header)
                .then(function (response) {
                    console.log("orders create success response : ", response.data);
                    localStorage.setItem("timestampOrder", response.data.body.timestamp);
                    return response.data.body.id
                })
                .catch(function (error) {
                    console.log("orders create error response : ", error);
                });
                
        return null;
        */
    }

    const onApproveOrder = (data) => {
        const timestamp = localStorage.getItem("timestampOrder");
        const order = {
            orderId: data.orderID,
            timestamp: timestamp
        };
        let header = {
        };
        return Api.post('/orders', order, header)
                .then(function (response) {
                    console.log("orders capture success response : ", response.data);
                    dispatch(resetItems());
                    addAlert("", "Commande passée avec succès", "success");
                    return response.data.body
                })
                .catch(function (error) {
                    console.log("orders capture error response : ", error);
                    addAlert("", "Erreur lors du passage de la commande", "error");
                });
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCheckboxChange = (e) => setIsCheckboxChecked(e.target.checked);

    return (
        <div className='product__container'>
            <form onSubmit={handleSubmit} ref={paypalButtonRef}>
                <div className='summary__main'>
                    <section className='summary__information'>
                        <h1>Information</h1>
                        <div className='summary__infos'>
                            <div>
                                <h3>Prenom</h3>
                                <input type='text' required={true} value={firstName} onChange={handleFirstNameChange}/>
                            </div>
                            <div>
                                <h3>Nom</h3>
                                <input type='text' required={true} value={lastName} onChange={handleLastNameChange}/>
                            </div>
                            <div>
                                <h3>Email</h3>
                                <input type='text' required={true} value={email} onChange={handleEmailChange}/>
                            </div>
                        </div>
                    </section>
                    <section className='summary__resume'>
                        <h1>Résumé de la commande</h1>
                        <div className='summary__container'>
                            {Array.isArray(items) && items.map((item, index) => (
                                <Item item={item} key={index} ></Item>
                            ))}
                        </div>
                    </section>
                </div>
                <section className='order__summary'>
                    <h1>PAIEMENT</h1>
                    <form className='summary__checkout'>
                        <img src='https://static.zara.net/static/images/payment/NewIcon/Icons_Payment_Methods/Payments/SVG/icon-payment-paypal_new.svg'/>
                        <div>
                            <p>PAYPAL</p>
                            <p>Vous allez être redirigé(e) vers le site Internet de PayPall, où vous pourrez finaliser le paiement.</p>
                        </div>
                    </form>
                </section>
                <section className='cart__footer'>
                    <div>
                        <input type='checkbox' required={true} onChange={handleCheckboxChange}/>
                        <p>J'ai lu et j'accepte les <a href="./CGV___produits_en_ligne_pour_les_particuliers.pdf" target='_blank'>Conditions générales d'achat</a></p>
                    </div>
                    <div>
                        Total:  {totalAmount} EUR
                    </div>
                    <div key={checkout} className='paypal__btn'>
                        <input type='submit' value={"AUTORISER LE PAIEMENT"}/>
                        {(!firstName || !lastName || !email || !isCheckboxChecked) ? <></> : (
                        <>
                            <PayPalButtonsWrapper
                                fundingSource="paypal"
                                disabled={false}
                                style={{
                                    layout: "vertical",
                                    label: "donate",
                                    height: 55
                                }}
                                onClick={(data, actions) => handlePayPalClick(data, actions)}
                                createOrder={(data, actions) => onCreateOrder(data, actions)}
                                onApprove={(data) => onApproveOrder(data)}
                                />
                        </>
                    )}
                    </div>
                </section>
            </form>
            <Footer/>
            <CustomAlert alerts={alerts} setAlerts={setAlerts}/>
        </div>
    );
};

export default OrderSummary;
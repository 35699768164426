import React from 'react';
import './Home.css'

const Home = () => {
    return (
        <>
            <section className='home'>
                <div className="marquee__rtl">
                    <div>
                        <a href='https://www.helloasso.com/associations/morphoz/evenements/megasouye-8' target='blanc'>https://www.helloasso.com/associations/morphoz</a>
                        <p>-</p>
                    </div>
                    <div>
                        <a href='https://www.helloasso.com/associations/morphoz/evenements/megasouye-8' target='blanc'>https://www.helloasso.com/associations/morphoz</a>
                        <p>-</p>
                    </div>
                </div>
                <div className='logo__anim'>
                    <img alt='gif' src='./LE-GIF-LOGO.gif'/>
                </div>
            </section>
        </>
    );
};

export default Home;